



























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ImportKeyfileInput } from "@/store/types";
import { SingletonWallet } from "@/js/wallets/SingletonWallet";
import { privateToAddress } from "ethereumjs-util";
import Guider from "@/components/misc/Guider.vue";
import GuideModal from "@/components/modals/GuideModal.vue";
import { bintools } from "@/AVA";
import { Buffer } from "avalanche";
import { strip0x } from "@avalabs/avalanche-wallet-sdk";

@Component({
  components: {
    Guider,
  },
})
export default class PrivateKey extends Vue {
  privatekey: string = "";
  isLoading: boolean = false;
  error: string = "";
  async access() {
    if (!this.canSubmit || this.isLoading) return;
    let parent = this;
    this.error = "";
    this.isLoading = true;
    let key = strip0x(this.privatekey);

    try {
      let res = await this.$store.dispatch("accessWalletSingleton", key);
      this.onsuccess();
    } catch (e) {
      this.onerror("Invalid Private Key.");
    }
  }
  onsuccess() {
    this.isLoading = false;
    this.privatekey = "";
  }
  onerror(e: any) {
    this.error = e;
    this.privatekey = "";
    this.isLoading = false;
  }
  get canSubmit(): boolean {
    if (!this.privatekey) {
      return false;
    }
    return true;
  }
}
